import Header from "../Header";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { slang } from "../Utils";

const proxyUrl = require("../../../package.json").proxy;

const BB_VERSION = "6.3"

const files_list = [
    {
        filename: "Setup_BreeBar_" + BB_VERSION + ".exe",
        descriptionFR: "Fichier d'installation du logiciel de caisse Breebar",
        descriptionEN: "Setup of cashbox management software Breebar",
        size: "238 Mo",
        serverPath: "/downloads/breebar",
    },
    {
        filename: "BreeBar_PSF.exe",
        descriptionFR: "Fichiers additionnels pour la prise de commande via réseau local LAN ou Wifi de Breebar",
        descriptionEN: "Additionnal Setup files for the local remote ordering of Breebar",
        size: "279 Mo",
        serverPath: "/downloads/psf",
    },
    {
        filename: "BB_maj_" + BB_VERSION + ".sav",
        descriptionFR: "Mise à jour cumulative Breebar " + BB_VERSION,
        descriptionEN: "Cumulative update Breebar " + BB_VERSION,
        size: "48.2 Mo",
        serverPath: "/downloads/bb_maj_current",
    },
]

const DownloadPage = function (props) {

    const { t } = useTranslation();

    return (
        <div>
            <Header user={props.user} />
            <div className="body m-3 p-3">
                <h1 className="m-3 py-3">{slang("Téléchargements", "Downloads")}</h1>

                <table className="table table-hover m-3 p-3">

                    <thead>
                        <tr>
                            <td>FileName</td>
                            <td>Description</td>
                            <td>Size</td>
                            <td>Download</td>
                        </tr>
                    </thead>
                    <tbody className="fs-3">
                        {
                            files_list.map((f, i) => {
                                return <tr key={i}>

                                    <td>
                                        <p>{f.filename}</p>
                                    </td>

                                    <td>
                                        <p>{slang(f.descriptionFR, f.descriptionEN)}</p>
                                    </td>
                                    <td>
                                        <p className="text-nowrap">{f.size}</p>
                                    </td>
                                    <td>
                                        <a download className="btn btn-lg btn-dark" href={proxyUrl + f.serverPath} target="_blank" rel="noopener noreferrer">{t("general.download")}</a>
                                    </td>
            
                                </tr>
                            })
                        }
                    </tbody>
                </table>

            </div>


            <Footer />
        </div>
    )
}

export default DownloadPage;